import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { css } from 'react-emotion'
import unified from 'unified'
import markdown from 'remark-parse'
import html from 'remark-html'
// import Box from 'grommet/components/Box'

import { Box, Flex } from '../../components/Layout'
import { Link, Timestamp } from '../../components/Misc'
import Layout from '../../layouts'
import { rhythm } from '../../utils/typography'

const linkStyles = css`
  box-shadow: none;
`

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allAirtable.edges')
    console.log(posts)

    return (
      <Layout>
        <Box>
          <Box
            width={[1, 1, 720]}
            m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
            px={[3, 3, 0]}
          >
            <h1>Blog</h1>
            {/* <Box>
              {posts
                .filter(post => post.node.data.title.length > 0)
                .map(({ node: post }, index) => {
                  return (
                    <Box key={post.id}>
                      <Link to={post.data.restaurant_name} className={linkStyles}>
                        <Timestamp>{post.data.date}</Timestamp>
                        <h3>{post.data.title}</h3>
                        <div
                          style={{
                            backgroundImage:
                              'url(' + post.data.image[0].url + ')',
                            backgroundSize: '100%',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '15rem',
                          }}
                        />
                        <div
                          style={{
                            marginTop: '1rem',
                            marginBottom: '2rem',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: String(
                              unified()
                                .use(markdown)
                                .use(html)
                                .processSync(
                                  post.data.PostMarkdown.split(/\s+/)
                                    .slice(0, 35)
                                    .join(' ')
                                )
                            ),
                          }}
                        />
                      </Link>
                    </Box>
                  )
                })}
            </Box> */}
          </Box>
        </Box>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allAirtable(sort: { fields: [data___restaurant_name], order: DESC }) {
      edges {
        node {
          id
          data {
            restaurant_name
            Name
            Start
            End
          }
        }
      }
    }
  }
`
